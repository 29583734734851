<template>
    <v-container class="ma-0 pa-0" fluid>

        <v-card elevation="6" outlined>
            <v-card-title>
                {{$t('customers.lang_nav_editcustgroup')}}
            </v-card-title>
            <v-divider class="ma-0 pa-0"/>
            <v-card-text class="ma-0 pa-0">
                <Datatable
                        :api-endpoint="ENDPOINTS.DATATABLES.CUSTOMERS.CUSTOMERGROUPS.ALL"
                        :datatable-headers="this.datatableHeaders"
                        :elevation="0"
                        :excel-columns="excelColumns"
                        :v-show="true"
                        @deleteEntries="deleteEntries"
                        @deleteEntry="deleteEntry"
                        @editEntry="editEntry"
                        excel-file-name="customer groups"
                        ref="customergroups"
                        show-delete-buttons
                        show-edit-buttons
                        show-pdf
                        show-select-checkbox
                        :permissionDelete="this.$store.getters['permissions/checkPermission']('customerGroupSettings')"
                        :permissionEdit="this.$store.getters['permissions/checkPermission']('customerGroupSettings')"
                />
            </v-card-text>
        </v-card>

    </v-container>
</template>

<script>
    import Datatable from "../../datatable/Datatable";
    //configs
    import {ENDPOINTS} from "../../../config";
    import {Events} from "../../../plugins/events";

    export default {
        name: "UserGroupComponent",
        components: {Datatable},
        data: () => {
            return {
                ENDPOINTS,
            }
        },
        methods: {
            delete(idsToDelete) {
                this.$swal({
                    title: this.$t('erp.lang_deleteReceiptItemText'),
                    text: this.$t('erp.lang_deleteReceiptItemText'),
                    icon: "warning",
                    cancelButtonText: this.$t('generic.lang_cancel'),
                    confirmButtonText: this.$t('generic.lang_delete'),
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMERGROUPS.DELETE, {
                            custGroupID: idsToDelete
                        }).then((res) => {

                            if (res.data.status === 'SUCCESS') {
                                Events.$emit("showSnackbar", {
                                    message: this.$t("erp.lang_itemSuccessfulDeleted"),
                                    color: "success"
                                });

                                this.$refs.customergroups.getDataFromApi();
                                this.$refs.customergroups.resetSelectedRows();
                            } else {
                                Events.$emit("showSnackbar", {
                                    color: "error",
                                    message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')
                                });
                            }
                        }).catch((err) => {
                            Events.$emit("showSnackbar", {
                                color: "error",
                                message: err.message
                            });
                        })
                    }
                });
            },
            deleteEntry(entry) {
                this.delete([entry.id])
            },
            deleteEntries(ids) {
                this.delete(ids);
            },
            editEntry(entry) {
                this.$router.push('/customer/custGroups/edit/' + entry.id)
            }

        },
        computed: {
            datatableHeaders: function () {
                return [
                    {
                        text: "ID",
                        value: "id",
                        sortable: false,
                        hide: true
                    },
                    {
                        text: this.$t('customers.lang_nameCustomerGroup'),
                        value: "name",
                        sortable: false
                    },
                    {
                        text: this.$t('customers.lang_CustomerGroupType'),
                        value: "type",
                    },
                ];
            },
            excelColumns: function () {
                return [
                    {
                        label: "ID",
                        field: "id"
                    },
                    {
                        label: this.$t('customers.lang_nameCustomerGroup'),
                        field: "name"
                    },
                    {
                        label: this.$t('customers.lang_CustomerGroupType'),
                        field: "type"
                    }
                ];
            }
        },
    }
</script>

<style scoped>

</style>