<template>
    <v-container class="pa-0" fluid>
        <page-title
                :heading="$t('customers.lang_custGroup') "
                :icon="icon"
                :subheading="$t('customers.lang_custGroup')"
                show-add-button
                :permissionAdd="this.$store.getters['permissions/checkPermission']('customerGroupSettings')"
        ></page-title>
        <div class="app-main__inner">
            <customer-group-component />
        </div>
    </v-container>
</template>

<script>
    //components
    import PageTitle from "../../../Layout/Components/PageTitle.vue";
    import CustomerGroupComponent from "../../../components/customers/customergroup/CustomerGroupComponent";


    export default {
        components: {
            CustomerGroupComponent,
            PageTitle,

        },
        data: () => ({
            icon: "pe-7s-users icon-gradient bg-tempting-azure"
        })
    };
</script>

<style>
</style>